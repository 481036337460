<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div :class="$style.image">
        <img src="./assets/avatar.png" alt="" />
      </div>
      <h3 :class="$style.title">
        <span>
          {{ t('title') }}
        </span>
      </h3>
      <ui-button
        uppercase
        :class="$style.btn"
        theme="accent"
        fill
        size="large"
        :loading="loading"
        @click="onLogin"
        v-analytics.click="{
          gtm: ['landing', 'registration', 'registration_link_button_click'],
          amplitude: [
            'button_click',
            {
              popup_name: 'need_auth',
              pop_text: t('title'),
              button_name: t('btn')
            }
          ]
        }"
      >
        {{ t('btn') }}
      </ui-button>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "title": "Войди, чтобы продолжить",
    "btn": "Войти"
  },
  "en": {
    "title": "Log in to continue",
    "btn": "Enter"
  }
}
</i18n>

<script setup>
import UiButton from '@/components/ui/button/index.vue'

const { t } = useI18n()
const loading = ref(false)
const { $sdk } = useNuxtApp()
const $emit = defineEmits(['close'])

const onLogin = async () => {
  try {
    loading.value = true
    await $sdk.module('auth').then(({ login }) => login())
    $emit('close', true)
  } catch (e) {
    $emit('close', false)
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" module>
.container {
  border-radius: 0.6rem;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: #252c3e;
  width: 37.5em;
  padding: 2.4rem 3.2rem;

  @include down(sm) {
    font-size: 0.9em;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .image {
      width: 21em;
      > img {
        width: 100%;
      }
    }
    > .title {
      font-style: normal;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      font-family: var(--primary-font);
      margin: 2.4em 0 3.2em;

      > span {
        font-size: 2.4em;
        line-height: 1.4;
      }
    }
    > .btn {
      width: 100%;

      span {
        font-weight: 700;
      }
    }
  }
}
</style>
